import "./footer.css";

function Footer(){
    return <div className="rodape">

         <div className="menu-rodape">

         </div>


    </div>
}

export default Footer;
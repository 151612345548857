import Navbar from "../../components/navbar/navbar.jsx";
import Vitrine from "../../components/vitrine/vitrine.jsx";
import Vitrine1 from "../../components/vitrine1/vitrine1.jsx";
import Vitrine2 from "../../components/vitrine2/vitrine2.jsx";
import Vitrine3 from "../../components/vitrine3/vitrine3.jsx";
import Footer from "../../components/footer/footer.jsx";
import Marketing from "../../components/marketing/marketing.jsx";

function Home(){
    return <>
        <Navbar/>
        <Marketing/>
        <Vitrine/>
        <Vitrine1/>
        <Vitrine2/>
        <Vitrine3/>
        <Footer/>
    </>
}

export default Home;
import "./marketing.css";

function Marketing(){
    return <div className = "container">

                 <div>
                     <br />
                     <h2 className="economize text-center">Economize até 90%</h2>
                     <h2 className="conta text-center">na conta de luz com</h2>
                     <h2 className="economize text-center">energia solar!!!</h2> 
                     <br />
                 </div>
        
           </div>;
}

export default Marketing

import "./vitrine.css";
import foto from "../../assets/mao-de-obra.png";

function Vitrine(){
    return <div className="vitrine-box text-center">
        
        <img src={foto} alt="Foto" />
        <div>
            <h2>Nossa equipe</h2>
            <p className="prod-vitrine-descricao">Mão de obra especializada</p>
        </div>
        <div>
            <a href="#" className="btn btn-dark btn-lg btn-banner">Fazer um orçamento</a>
        </div>
    </div>
}

export default Vitrine;
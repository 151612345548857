import "./vitrine1.css";
import foto from "../../assets/modelos.png";

function Vitrine1(){
    return <div className="vitrine-box text-center">
        <img src={foto} alt="Foto" />
        
        <div>
            <h2>Nossas placas</h2>
            <p className="prod-vitrine-descricao">Placas disponíveis</p>
        </div>

        <div>
          <a href="#" className="btn btn-dark btn-lg btn-banner">Fazer um orçamento</a>
        </div>
    </div>
}

export default Vitrine1;